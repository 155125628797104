<template>
    <div>
        <div class="container header-fixed">
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="section pt-md-5 pt-3">
                        <div class="d-block">
                            <h1>Formulir Saya</h1>
                        </div>

                        <!-- Search -->
                        <div class="d-flex align-items-center mb-0 d-flex-mobile">
                            <div class="input-group input-group-merge mb-0 flex-grow-1 mb-md-0 mb-3">
                                <input type="search" class="form-control form-control-prepended search"
                                    placeholder="Cari formulir disini..." v-model="filter.keyword" v-on:keyup.enter="getData">
                                <div class="input-group-prepend">
                                    <div class="input-group-text">
                                        <span class="fe fe-search"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="ml-md-3 ml-0 w-100-mobile mb-md-0 mb-3" style="width:300px;">
                                <select name="" id="" class="form-control custom-select" v-model="filter.wilayah" v-on:change="getData">
                                    <option value="">Semua Lokasi</option>
                                    <option v-for="(item,index) in listWilayah" :key="index" :value="item.value">{{item.value}}</option>
                                </select>
                            </div>
                            <div class="ml-md-3 ml-0 w-100-mobile mb-md-0 mb-3" style="width:300px;">
                                <select name="" id="" class="form-control custom-select" v-model="filter.sub_unit_id" v-on:change="getData">
                                    <option value="">Semua Sub Unit</option>
                                    <option v-for="(item,index) in listSubUnit" :key="index" :value="item.id">{{item.title}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- Formulir List Front -->
                        <div class="section pb-3">
                            <div class="d-flex align-items-center justify-content-between mb-3 pb-3 border-bottom">
                                <h4 class="m-0">{{listData.length}} formulir</h4>
                            </div>


                            <div v-if="fetching">
                                <div class="row hide-mobile">
                                    <div v-for="(item,index) in [1,2,3,4]" :key="index" class="col-md-3 mb-3 col-load">
                                        <div class="card h-100 m-0">
                                            <div class="d-block p-3 h-100 link-btn-white">
                                                <div class="d-flex flex-column justify-content-between h-100">
                                                    <div class="flex-grow-1">
                                                        <div class="card-img-news mb-3 add-skeleton-load delay-1"></div>
                                                        <div class="skeleton-bar">
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-md mb-2"></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="skeleton-bar">
                                                            <div class="sb-sm sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-bold"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row show-mobile">
                                    <div v-for="(item,index) in [1,2]" :key="index" class="col-md-3 mb-3 col-load">
                                        <div class="card h-100 m-0">
                                            <div class="d-block p-3 h-100 link-btn-white">
                                                <div class="d-flex flex-column justify-content-between h-100">
                                                    <div class="flex-grow-1">
                                                        <div class="card-img-news mb-3 add-skeleton-load delay-1"></div>
                                                        <div class="skeleton-bar">
                                                            <div class="sb-lg mb-2"></div>
                                                            <div class="sb-md mb-2"></div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="skeleton-bar">
                                                            <div class="sb-sm sb-thin mb-2"></div>
                                                            <div class="sb-lg sb-bold"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            
                            <div v-if="!fetching">

                                <!-- Empty State -->
                                <div v-if="listData.length == 0" class="empty-content" data-empty-title="Daftar formulir tidak tersedia">
                                    <div class="row hide-mobile">
                                        <div v-for="(item,index) in [1,2,3,4]" :key="index" class="col-md-3 mb-3 col-load">
                                            <div class="card h-100 m-0">
                                                <div class="d-block p-3 h-100 link-btn-white">
                                                    <div class="d-flex flex-column justify-content-between h-100">
                                                        <div class="flex-grow-1">
                                                            <div class="card-img-news mb-3 add-skeleton-load skeleton-empty delay-1"></div>
                                                            <div class="skeleton-bar skeleton-empty">
                                                                <div class="sb-lg mb-2"></div>
                                                                <div class="sb-md mb-2"></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="skeleton-bar skeleton-empty">
                                                                <div class="sb-sm sb-thin mb-2"></div>
                                                                <div class="sb-lg sb-bold"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row show-mobile">
                                        <div v-for="(item,index) in [1,2]" :key="index" class="col-md-3 mb-3 col-load">
                                            <div class="card h-100 m-0">
                                                <div class="d-block p-3 h-100 link-btn-white">
                                                    <div class="d-flex flex-column justify-content-between h-100">
                                                        <div class="flex-grow-1">
                                                            <div class="card-img-news mb-3 add-skeleton-load skeleton-empty delay-1"></div>
                                                            <div class="skeleton-bar skeleton-empty">
                                                                <div class="sb-lg mb-2"></div>
                                                                <div class="sb-md mb-2"></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div class="skeleton-bar skeleton-empty">
                                                                <div class="sb-sm sb-thin mb-2"></div>
                                                                <div class="sb-lg sb-bold"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- Show Data -->
                                <div v-if="listData.length > 0" class="row row-sm">
                                    <div v-for="(item, index) in listData" :key="index" class="col-md-3 mb-md-4 mb-3">
                                        <div class="card h-100 m-0">
                                            <div class="d-block h-100">
                                                <div class="d-flex flex-column h-100">
                                                    <div class="mb-0 flex-grow-1">
                                                        <div class="card-img-top" v-bind:style="{ 'background-image': 'url(' + item.cover + ')' }"></div>
                                                        <div :class="'d-block px-3 py-2 text-xs text-capitalize '+item.background_position">
                                                            {{item.message_position}}                                                        
                                                        </div>
                                                        <div class="d-block p-3">
                                                            <div class="badge badge-primary">{{item.kategori}}</div>
                                                            <div v-if="item.status_registrasi == 'LOLOS' && item.status_cicilan == 1 && item.list_cicilan.length > 0 && item.list_cicilan.length != item.count_cicilan" class="ml-2 badge badge-warning">{{item.count_cicilan}}/{{item.list_cicilan.length}} Cicilan</div>
                                                            <div v-if="item.status_registrasi == 'LOLOS' && item.status_cicilan == 1 && item.list_cicilan.length == item.count_cicilan" class="ml-2 badge badge-success">Cicilan Lunas</div>
                                                            <div class="mb-1 text-sm mt-0">{{item.unit}}</div>
                                                            <h3 class="mb-2 mt-0">{{item.nama_formulir}}</h3>
                                                            <div class="d-block px-3 py-2 bg-light rounded text-sm mb-2">
                                                                {{item.keterangan}}
                                                            </div>
                                                            <div class="lh-sm lh-sm text-sm">{{item.nama_gelombang}}</div>
                                                            <div class="text-muted mb-0 text-sm mt-1">{{item.nama_periode}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="d-block px-3 pb-3 pt-0">
                                                        <!-- Dots Status -->
                                                        <div class="dots-status mb-3">
                                                            <div v-for="(itemProgres, index) in item.progress_registrasi" :key="index">
                                                                <div :class="'dot '+itemProgres.class"></div>
                                                            </div>
                                                        </div>
                                                        <router-link v-bind:to="'/calon-siswa/formulir/registrasi/'+item.id" class="btn btn-primary-light btn-block">Lihat</router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import { mapState } from 'vuex'

    export default {
        name: 'Beranda',
        components: {
            
        },
        metaInfo: {
            title: 'SchoolPro',
            titleTemplate: '%s - Formulir Saya'
        },
        data() {
            return {
                search: "",
                listData: [],
                pageOfItems: [],
                fetching: true,
                listWilayah : [],
                listSubUnit : [],
                filter : {
                    keyword     :'',
                    wilayah     :'',
                    sub_unit_id :'',
                }
            }
        },
        mounted() {
            this.$store.dispatch('loadUserData')
        },
        computed: mapState([
            'userdata'
        ]),
        created() {
            this.getData();
            this.getWilayah();
            this.getSubUnit();
        },
        methods: {
            getData: function() {
                this.listData = [];
                this.fetching = true;
                let uri = this.$apiconfig + 'website/registrasi/my_formulir';
                this.$http.get(uri, {
                    params: {
                        keyword     : this.filter.keyword,
                        wilayah     : this.filter.wilayah,
                        sub_unit_id : this.filter.sub_unit_id
                    }
                }).then(res => {					
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.listData = getResponse.data;
                    } else {
                        this.listData = [];
                    }
                    this.fetching = false;
                });
            },
            getWilayah: function() {
                let uri = this.$apiconfig + 'website/wilayah/list';
                this.$http.get(uri).then(res => {					
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.listWilayah = getResponse.data;
                    } else {
                        this.listWilayah = [];
                    }
                });
            },
            getSubUnit: function() {
                let uri = this.$apiconfig + 'website/sub_unit/list';
                this.$http.get(uri).then(res => {					
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.listSubUnit = getResponse.data;
                    } else {
                        this.listSubUnit = [];
                    }
                });
            },
            showMessage: function() {
                this.$swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: "Formulir sudah kadaluarsa",
                })
            }
        }
    }
</script>